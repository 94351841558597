import { webClient } from "@api/web/WebClient";
import { useQueryParameters } from "@hooks/navigation";
import { WebUsersAuthenticatedGetUserResponse } from "@reshopper/web-client";
import { getJwtCookiePayload } from "@utils/swaggerClientUtils";
import { useEffect, useState } from "react";

type QueryParameters = {
	token: string;
};

export const useAuthenticatedUser = () => {
	const params = useQueryParameters<QueryParameters>();
	const [authenticatedUser, setAuthenticatedUser] = useState<WebUsersAuthenticatedGetUserResponse | null | undefined>(undefined);


	useEffect(() => {
		
		const fetchAuthenticatedUser = async () => {

			if (!getJwtCookiePayload()) {
				if (!params.token) {
					setAuthenticatedUser(null);
					return;
				}

				await webClient().webAuthenticationsSetJwtCookiesFromJwtPost({
					body: {
						apiJwt: params.token
					}
				});
			}

			const authenticatedUserResponse = await webClient().webUsersAuthenticatedGet();
			setAuthenticatedUser(authenticatedUserResponse.authenticatedUser ?? null);

		}

		fetchAuthenticatedUser();
	}, [params.token, getJwtCookiePayload])

	return authenticatedUser;
};	