import { webClient } from "@api/web/WebClient";
import { createErrorToast } from "@components/ToastContainer";
import ReshopperButton from "@components/web/Button";
import DownloadAppButton from "@components/web/DownloadAppButton";
import { ReshopperInput } from "@components/web/Input";
import { useLocalization } from "@hooks/localization";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getJwtCookiePayload, isJwtTokenExpired } from "@utils/swaggerClientUtils";
import { FormEvent, useMemo, useState } from "react";
import styles from "./LoginDialog.module.scss";

export default function LoginDialog(props: {
	open: boolean;
	onClosed: () => void;
}) {
	const [isLoading, setIsLoading] = useState(false);
	const t = useLocalization();

	const [email, setEmail] = useState<string>(() => {
		if (typeof window === "undefined")
			return "";

		return window.localStorage.getItem('email') || "";
	});
	const [password, setPassword] = useState<string>("");

	const isButtonDisabled = useMemo(
		() => isLoading || !email || !password,
		[isLoading, email, password]);

	const onCancelClicked = () => {
		props.onClosed && props.onClosed();
	};

	const onSignInClicked = async (e: FormEvent<HTMLElement>) => {
		e.preventDefault();

		if (email === undefined) {
			setEmail("");
			return;
		}

		if (password === undefined) {
			setPassword("");
			return;
		}
		setIsLoading(true);

		try {
			await webClient().webAuthenticationsLoginPost({
				body: {
					email,
					password
				}
			});;

			if (!!getJwtCookiePayload() && isJwtTokenExpired(getJwtCookiePayload()!)) {
				setIsLoading(false);
				createErrorToast("Din session er udløbet.");
				return;
			}

			window.location.reload();
		} catch (ex: unknown) {
			console.error(ex);
			createErrorToast(t("LogIn_Text"));
		}
		finally {
			setIsLoading(false);
		}
	};


	return <Dialog open={props.open} onClose={onCancelClicked} fullWidth>
		<DialogTitle className={styles.dialogTitle}>{t("LogIn_Text")}</DialogTitle>
		<DialogContent>
			<Box component="form" onSubmit={(e) => onSignInClicked(e)} className={styles.formContainer}>
				<ReshopperInput
					value={email}
					onChange={ev => {
						setEmail(ev.target.value);
						typeof window !== "undefined" && window.localStorage.setItem("email", ev.target.value);
					}}
					className={styles.textField}
					label={t("LogIn_Email")}
					type="email"
					style={{
						marginTop: 0,
						paddingTop: 0
					}} />

				<ReshopperInput
					value={password}
					onChange={ev => setPassword(ev.target.value)}
					className={styles.textField}
					label={t("LogIn_Password")}
					type="password" />

				<ReshopperButton
					disabled={isButtonDisabled}
					type="submit"
					variant="contained"
					color="primary"
					size="large"
				>
					{t("LogIn_Text")}
				</ReshopperButton>
			</Box>

			<Box style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
				<Typography>{t("New_User_Text")} <span style={{ whiteSpace: "nowrap" }}><DownloadAppButton showAsLink /></span></Typography>
			</Box>
		</DialogContent>
	</Dialog>
}