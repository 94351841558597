export function injectPerformanceObserver() {
    if(typeof window === "undefined")
        return;

    const url = new URL(window.location.href);
    if(!url.searchParams.has("performance"))
        return;

    if(typeof PerformanceObserver === "undefined" || typeof PerformanceObserver.supportedEntryTypes === "undefined")
        return;

    for(let entryType of PerformanceObserver.supportedEntryTypes) {
        new PerformanceObserver((entryList) => {
            for (const entry of entryList.getEntries()) {
                console.log(`${entryType} performance:`, entry.startTime, entry);
            }
        }).observe({
            type: entryType, 
            buffered: true
        });
    }
}

/**
 * JavaScript is usually single-threaded, unless the Web Workers standard is used in Ecmascript. The "workerpool" NPM package can help with that, to process expensive tasks while the UI is still responsive.
 */
export async function runOnSeparateThread<T extends (...args: any[]) => any>(func: T, args: Parameters<T>) {
	const workerPool = await import("workerpool");
	const pool = workerPool.pool();
	try {
		return await pool.exec(func, args);
	} finally {
		await pool.terminate(true);
	}
}