import { TextField, TextFieldProps } from "@material-ui/core";
import styles from "./Input.module.scss";

export function ReshopperInput(props: TextFieldProps) {
    return <TextField
        variant="outlined"
        {...props}
        className={`${props.className} ${styles.root}`}
        InputProps={{
            ...(props.InputProps || {}),
            className: `${styles.inner} ${props.InputProps?.className}`,
        }}
    />;
}