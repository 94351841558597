import LoginDialog from "@components/web/LoginDialog";
import ProfilePicture from "@components/web/users/ProfilePicture";
import { useAuthenticatedUser } from "@hooks/authentication";
import { useQueryParameter } from "@hooks/navigation";
import { Box, CircularProgress, Container, IconButton, Link } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { injectPerformanceObserver } from "@utils/performance";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logoIcon from "../../../pages/images/newFrontpage/logo/logo-white-icon.svg";
import styles from "./FrontPageLayout.module.scss";


export const FrontPageLayout = (props: {
	children: React.ReactNode,
	includeFooter?: boolean,
	withoutAppBar?: boolean,
	withLegacyBackgroundColor?: boolean,
	removeBlueBodyBackground?: boolean
}) => {
	useEffect(() => injectPerformanceObserver());

	const blueBackgroundClass = props.removeBlueBodyBackground ? styles.bodyBackgroundNotBlue : styles.bodyBackgroundBlue;

	return <>
		<Helmet>
			<body className={blueBackgroundClass} />
		</Helmet>
		<div className={styles.frontPage}>
			{!props.withoutAppBar && <AppBar />}

			<div className={styles.content}>
				<div className={styles.contentSection}>
					{props.children}
				</div>

				{props.includeFooter && <>
					<Box
						component="footer"
						textAlign="center"
						className={styles.footer}>
						<Container>
							<Box style={{ marginBottom: "30px" }}>
								<img src={logoIcon} width={70} />
							</Box>
							<Box component="nav" className={styles.footerMenu}>
								<Link href="/dk/brugervilkaar">Brugervilkår</Link>
								<Link href="/handelsbetingelser">Handelsbetingelser</Link>
								<Link href="/dk/privatlivspolitik">Privatlivspolitik</Link>
								<Link href="https://reshopperdk.helpscoutdocs.com/">FAQ</Link>
								<Link href="/dk/kontakt">Kontakt</Link>
							</Box>
						</Container>
					</Box>
				</>}
			</div>
		</div>
	</>;
};

export function AppBar() {
	const [isFromApp] = useQueryParameter("fromApp");
	if (isFromApp)
		return null;

	return <aside className={styles.appBar}>
		<MenuItem>
			<LoginMenu />
		</MenuItem>
	</aside>
}

function MenuItem(props: {
	children: React.ReactNode | React.ReactNode[]
}) {
	return <div className={styles.menuItem}>
		{props.children}
	</div>;
}

function LoginMenu() {
	const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
	const authenticatedUser = useAuthenticatedUser();

	if (authenticatedUser === undefined)
		return <Box className={styles.circularProgress}>
			<CircularProgress size={24} />
		</Box>;

	if (authenticatedUser === null) {
		return <>
			<IconButton
				title="log ind"
				onClick={() => setLoginDialogOpen(true)}>
				<LockIcon />
			</IconButton>
			{isLoginDialogOpen &&
				<LoginDialog
					open
					onClosed={() => setLoginDialogOpen(false)} />}
		</>;
	}

	return <ProfilePicture
		images={authenticatedUser?.profileImages}
		size={48} />;
}