import WebpImage from "@components/WebpImage";
import { WebCdnImageModel } from "@reshopper/web-client";
import { SyntheticEvent } from "react";
import styles from "./ProfilePicture.module.scss";

export default (props: {
    size: number,
    href?: string,
    images: WebCdnImageModel[]|undefined,
    imageClassName?: string,
    wrapperClassName?: string,
    onClick?: (ev: SyntheticEvent) => void
}) => {
    return <WebpImage alt="Profile" 
        href={props.href}
        fallbackSrc="https://static.reshopper.com/images/avatar.png"
        images={props.images}
        onClick={ev => props.onClick && props.onClick(ev)}
        imageClassName={props.imageClassName} 
        wrapperClassName={styles.root + " " + props.wrapperClassName}
        width={props.size}
        height={props.size}
    />
}